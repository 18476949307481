.dashboard-page .content .dashboard-area .content-box .MuiBox-root {
    position: relative;
}

.dashboard-page .content .dashboard-area .content-box .MuiBox-root .MuiStepper-root {
    display: flex;
    justify-content: space-between;
}

.dashboard-page .content .dashboard-area .content-box .MuiBox-root .MuiStepper-root .MuiStep-root {
    position: relative;
    padding: 15px 0;
    border-right: none;
    width: 100%;
    border-left: none;
}

.dashboard-page .content .dashboard-area .content-box .MuiBox-root .MuiStepper-root .MuiStepConnector-root.MuiStepConnector-horizontal.Mui-disabled.css-j5w0w9-MuiStepConnector-root {
    display: none;
}

/* .dashboard-page .content .dashboard-area .content-box .MuiBox-root .MuiStepper-root .MuiStep-root.Mui-completed {
    background-color: #fafcff;
} */
.dashboard-page .content .dashboard-area .content-box .MuiBox-root .MuiStepper-root .MuiStep-root.Mui-completed .Mui-completed {
    color: #01194d;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .table-area .table tbody tr td .edit-btn i {
    margin-right: 4px;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner {
    position: relative;
    padding: 35px 2px 20px;
}

.dashboard-page .content .dashboard-area .content-box .MuiBox-root .MuiStepper-root .MuiStep-root .MuiStepLabel-root {
    display: block;
}

.dashboard-page .content .dashboard-area .content-box .MuiBox-root .MuiStepper-root .MuiStep-root .MuiStepLabel-root .MuiSvgIcon-root {
    margin-bottom: 18px;
    color: #0065ff50;
}

.dashboard-page .content .dashboard-area .content-box .MuiBox-root .MuiStepper-root .MuiStep-root .MuiStepLabel-root .MuiSvgIcon-root.Mui-active {
    color: #0065ff;
}

.dashboard-page .content .dashboard-area .content-box .MuiBox-root .MuiStepper-root .MuiStep-root .MuiStepLabel-root {
    position: relative;
}

.dashboard-page .content .dashboard-area .content-box .MuiBox-root .MuiStepper-root .MuiStep-root .MuiStepLabel-root:before {
    content: "";
    position: absolute;
    right: 0;
    width: 93%;
    height: 1px;
    background-color: #0065ff;
    top: 16px;
}

.dashboard-page .content .dashboard-area .content-box .MuiBox-root .MuiStepper-root .MuiStep-root:last-child {
    width: auto;
}

.dashboard-page .content .dashboard-area .content-box .MuiBox-root .MuiStepper-root .MuiStep-root:last-child .MuiStepLabel-root::before {
    display: none;
}

.dashboard-page .content .dashboard-area .content-box .MuiBox-root .MuiStepper-root .MuiStep-root .MuiStepLabel-root .MuiStepLabel-labelContainer {
    color: #808ca6;
    position: relative;
    left: -9%;
}

.dashboard-page .content .dashboard-area .content-box .MuiBox-root .MuiStepper-root .MuiStep-root .MuiStepLabel-root .MuiStepLabel-labelContainer .MuiStepLabel-label {
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
}

.dashboard-page .content .dashboard-area .content-box .MuiBox-root .MuiStepper-root .MuiStep-root .MuiStepLabel-root .MuiStepLabel-labelContainer .MuiStepLabel-label.Mui-active {
    color: #01194d !important;
    font-weight: 600;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .connect-box {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f6f8ff;
    padding: 0 25px 40px;
    margin-bottom: 40px;
    justify-content: space-between;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner.object-tab .checkbox-box {
    position: relative;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner.object-tab .checkbox-box h4 {
    font-size: 16px;
    margin: 40px 0 20px;
    color: #808ca6;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner.object-tab .checkbox-box .checkbox-area {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner.object-tab .checkbox-box .checkbox-area .label-box {
    width: 25%;
    font-size: 16px;
    color: #01194d;
    font-weight: 500;
    cursor: pointer;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .title-area.style-two {
    margin-bottom: 25px;
    margin-top: 25px;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner.object-tab .checkbox-box .checkbox-area .label-box .MuiButtonBase-root {
    background-color: transparent !important;
    border: none !important;
    padding: 0 !important;
    top: -2px;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner.object-tab .checkbox-box .checkbox-area .label-box .MuiButtonBase-root .MuiSvgIcon-root {
    color: #0065ff;
    font-size: 19px;
    margin-right: 16px;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .connect-box .left-area {
    position: relative;
    display: flex;
    align-items: center;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .connect-box .left-area .icon {
    margin-right: 15px;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .connect-box .left-area .icon img {
    width: 38px;
    border-radius: 5px;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .connect-box .left-area h4 {
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 700;
    font-family: "Work Sans", sans-serif;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .connect-box .left-area h4 span {
    font-size: 15px;
    margin-left: 7px;
    font-weight: 400;
}

.dashboard-page .content .dashboard-area .content-box .MuiButtonBase-root {
    position: relative !important;
    margin-bottom: 0 !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #fff !important;
    border: 2px solid #0065ff !important;
    transition: all 0.3s ease-in-out !important;
    display: inline-block !important;
    padding: 8px 30px !important;
    background-color: #0065ff !important;
    border-radius: 6px !important;
    z-index: 9 !important;
    overflow: hidden !important;
    font-family: "Work Sans", sans-serif;
}

.dashboard-page .content .dashboard-area .content-box .MuiButtonBase-root:hover {
    background-color: transparent !important;
    color: #0065ff !important;
    border: 2px solid #0065ff !important;
}

.dashboard-page .content .dashboard-area .content-box .MuiButtonBase-root:disabled,
.dashboard-page .content .dashboard-area .content-box .MuiButtonBase-root[disabled] {
    background-color: #d5d5d5 !important;
    border: 1px solid #d5d5d5 !important;
    color: #fff !important;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .title-area {
    position: relative;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .title-area h5 {
    margin-bottom: 0;
    font-size: 16px;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .title-area .select-area {
    display: flex;
    align-items: center;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .title-area .select-area label {
    font-size: 14px;
    margin-right: 15px;
    color: #0065ff;
    font-weight: 600;
    margin-bottom: 0;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .title-area .select-area .MuiInputBase-root {
    padding: 0;
    margin: 0;
    font-size: 14px;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .title-area .select-area .MuiInputBase-root fieldset {
    border: none;
    padding: 0;
    margin: 0;
}

/* .dashboard-page .content .dashboard-area .content-box .MuiBox-root .MuiStepper-root .MuiStep-root.Mui-completed:before {
    content: "";
    position: absolute;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 31px 0 31px 24.6px;
    border-color: transparent transparent transparent #fafcff;
    transform: rotate(0deg);
    right: -23px;
    top: 0;
} */
.dashboard-page .content .dashboard-area .content-box .connect-inner .title-area .select-area .MuiInputBase-root #supported-objects-multiple-checkbox {
    padding: 2px 0 0 24px;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .title-area .select-area .MuiInputBase-root svg {
    position: relative;
    fill: #0065ff;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .title-area {
    position: relative;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .title-area h5 {
    margin-bottom: 0;
    font-size: 16px;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .title-area .select-area {
    display: flex;
    align-items: center;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .title-area .select-area label {
    font-size: 14px;
    margin-right: 15px;
    color: #0065ff;
    font-weight: 600;
    margin-bottom: 0;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .title-area .select-area .MuiInputBase-root {
    padding: 0;
    margin: 0;
    font-size: 14px;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .title-area .select-area .MuiInputBase-root fieldset {
    border: none;
    padding: 0;
    margin: 0;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .title-area .select-area .MuiInputBase-root #supported-objects-multiple-checkbox {
    padding: 2px 0 0 24px;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .title-area .select-area .MuiInputBase-root svg {
    position: relative;
    fill: #0065ff;
}

[aria-labelledby="supported-objects-multiple-checkbox-label"] {
    position: relative;
}

[aria-labelledby="supported-objects-multiple-checkbox-label"] li {
    font-size: 14px;
}

[aria-labelledby="supported-objects-multiple-checkbox-label"] li svg {
    width: 20px;
}

[aria-labelledby="supported-objects-multiple-checkbox-label"] li .MuiButtonBase-root {
    padding: 0;
    margin-right: 8px;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .add-property {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 51px 0 20px;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .add-property .acounts a {
    font-weight: 600;
    color: #0065ff;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .add-property .right-area {
    position: relative;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .add-property .right-area .btn-style-two {
    padding: 7px 15px;
    margin-left: 23px;
    font-size: 14px;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .add-property .right-area {
    position: relative;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .add-property .right-area .btn-style-two {
    padding: 7px 15px;
    margin-left: 23px;
    font-size: 14px;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .table-area {
    position: relative;
    margin-top: 50px;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .table-area .table {
    position: relative;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .table-area .table thead tr th {
    border-top: none;
    border-bottom: 1px solid #eff4ff;
    font-size: 16px;
    color: #1f3462;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .table-area .table tbody tr td {
    border-bottom: 1px solid #eff4ff;
    font-size: 15px;
    color: #1f3462;
    vertical-align: middle;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .table-area .table tbody tr td:last-child {
    text-align: right;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .table-area .table tbody tr td .edit-btn {
    border: none;
    background-color: transparent;
    font-size: 16px;
    color: #0065ff;
    font-weight: 500;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .table-area .table tbody tr td .edit-btn .icon {
    width: 17px;
    display: inline-block;
    margin-right: 4px;
    position: relative;
    top: -1px;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .table-area .table tbody tr td input {
    border: 1px solid #eff4ff;
    padding: 10px;
    font-size: 14px;
    color: #1f3462;
    border-radius: 5px;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .table-area .table tbody tr td input::placeholder {
    color: #1f346290;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .title-area.overview-title h5 {
    font-weight: 600;
    font-size: 18px;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .title-area.overview-title .btn-style-two {
    font-size: 14px;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .title-area.overview-title h5 {
    font-weight: 600;
    font-size: 18px;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .title-area.overview-title .btn-style-two {
    font-size: 14px;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .table-area .table tbody tr td .company-data {
    display: flex;
    align-items: center;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .table-area .table tbody tr td .company-data .icon {
    display: inline-block;
    width: 23px;
    margin-right: 9px;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .table-area .table tbody tr td .company-data h4 {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 600;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .table-area .table tbody tr td a {
    margin-left: 32px;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .table-area.checkout-table .table tbody tr td:last-child {
    text-align: left;
}

.dashboard-page .content .dashboard-area .content-box .MuiBox-root .migrat-btn {
    border: none;
    background-color: transparent;
    margin-right: 22px;
    font-size: 14px;
    font-weight: 500;
    color: #0065ff;
}

.dashboard-page .content .dashboard-area .content-box .MuiBox-root .migrat-btn .icon {
    display: inline-block;
    width: 18px;
    margin-right: 7px;
}

.modal-payment {
    max-width: 580px !important;
}

.modal-payment.right {
    height: 91vh;
    max-width: 792px !important;
    position: fixed;
    right: 0;
    top: -35px;
}

.modal-payment.right .modal-content {
    height: 100vh;
}

.modal-payment .modal-content {
    background-color: #f7f9fc;
}

.payment-box {
    position: relative;
    padding: 20px 20px !important;
}

.modal-payment .payment-box .react-tabs__tab-list {
    margin: 0;
    border-bottom: 0;
    display: flex;
    flex-wrap: wrap;
}

.modal-payment .payment-box .react-tabs__tab-list .react-tabs__tab {
    position: relative;
    width: 43%;
    font-size: 14px;
    font-weight: 500;
    opacity: 1;
    background-color: #ffffff;
    min-height: 61px;
    margin: 0 3px;
    border-radius: 6px;
    border: 2px solid #eff2f6;
    color: #01194d70;
}

.modal-payment .payment-box .react-tabs__tab-list .react-tabs__tab .icon {
    display: block;
    width: 21px;
    opacity: 0.4;
}

.modal-payment .payment-box .react-tabs__tab-list .react-tabs__tab:last-child {
    width: 10%;
}

.modal-payment .payment-box .react-tabs__tab-list .react-tabs__tab--selected {
    border-color: #718197;
    opacity: 1;
    color: #01194d;
}

.modal-payment .payment-box .react-tabs__tab-list .react-tabs__tab--selected .icon {
    opacity: 1;
}

.react-tabs__tab:focus:after {
    display: none;
}

.modal-payment .payment-box {
    position: relative;
    padding: 30px 30px;
}

.modal-payment .payment-box .react-tabs__tab-list {
    margin: 0;
    border-bottom: 0;
    display: flex;
    flex-wrap: wrap;
}

.modal-payment .payment-box .react-tabs__tab-list .react-tabs__tab {
    position: relative;
    width: 43%;
    font-size: 14px;
    font-weight: 500;
    opacity: 1;
    background-color: #ffffff;
    min-height: 61px;
    margin: 0 3px;
    border-radius: 6px;
    border: 2px solid #eff2f6;
    color: #01194d70;
}

.modal-payment .payment-box .react-tabs__tab-list .react-tabs__tab .icon {
    display: block;
    width: 21px;
    opacity: 0.4;
}

.modal-payment .payment-box .react-tabs__tab-list .react-tabs__tab:last-child {
    width: 10%;
}

.modal-payment .payment-box .react-tabs__tab-list .react-tabs__tab--selected {
    border-color: #718197;
    opacity: 1;
    color: #01194d;
}

.modal-payment .payment-box .react-tabs__tab-list .react-tabs__tab--selected .icon {
    opacity: 1;
}

.modal-payment .payment-box .card-box {
    position: relative;
    padding-top: 30px;
}

.modal-payment .payment-box .card-box .group-form {
    position: relative;
    margin-bottom: 20px;
}

.modal-payment .payment-box .card-box .group-form label {
    display: block;
    width: 100%;
    color: #01194d;
    font-size: 16px;
}

.modal-payment .payment-box .card-box .group-form input {
    width: 100%;
    padding: 10px 15px;
    border-radius: 5px;
    border: 1px solid #d5d7d8;
    color: #01194d;
    font-size: 16px;
}

.modal-payment .payment-box .card-box .group-form input::placeholder {
    color: #01194d50;
}

.modal-payment .payment-box .card-box .group-form .icon {
    position: absolute;
    top: 39px;
    z-index: 5;
    right: 8px;
    text-align: right;
    width: 30%;
}

.modal-payment .payment-box .card-box .group-form .icon img {
    width: 50%;
}

.modal-payment .payment-box .card-box .group-form .btn-style-three {
    width: 100%;
}

.modal-payment .payment-box .card-box .group-form.cvv-group .icon {
    width: 59px;
}

.dashboard-page .content .dashboard-area .content-box .MuiBox-root .MuiStepper-root .MuiStep-root.Mui-completed {}

.dashboard-page .content .dashboard-area .content-box .MuiBox-root .MuiStepper-root .MuiStep-root.Mui-completed .Mui-completed {
    color: #01194d;
}

i.fa.fa-plus.fa-lg.mr-2 {}

.dashboard-page .content .dashboard-area .content-box .connect-inner .table-area .table tbody tr td .edit-btn i {
    margin-right: 4px;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .table-area .table tbody tr td .MuiAutocomplete-root {
    border: none;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .table-area .table tbody tr td .MuiAutocomplete-root .MuiFormControl-root {
    border: 1px solid #eff4ff;
    padding: 5px 0;
    border-radius: 6px;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .table-area .table tbody tr td .MuiAutocomplete-root .MuiFormControl-root label {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .table-area .table tbody tr td .MuiAutocomplete-root .MuiInputBase-root {
    border: none !important;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .table-area .table tbody tr td .MuiAutocomplete-root .MuiInputBase-root fieldset {
    border: none;
    padding: 0;
    margin: 0;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .table-area .table tbody tr td .MuiAutocomplete-root .MuiFormControl-root input {
    font-size: 14px;
    border: none;
    padding: 0;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .table-area .table-responsive {
    overflow-x: visible;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .table-area .table tbody tr td .MuiAutocomplete-root .MuiFormControl-root .MuiAutocomplete-endAdornment .MuiButtonBase-root {
    background-color: transparent !important;
    padding: 0 !important;
    color: #00000040 !important;
    border: none !important;
}

.modal-payment .payment-box .group-form,
.MuiPaper-root.MuiPaper-elevation .connect-inner .payment-box .group-form {
    margin-bottom: 20px;
}

.modal-payment .payment-box .group-form label,
.MuiPaper-root.MuiPaper-elevation .connect-inner .payment-box .group-form label {
    display: block;
    width: 100%;
    font-size: 16px;
    margin-bottom: 0px;
    color: #000000;
}

.label-modal .modal-content {
    background-color: #fff;
}

.modal-payment .payment-box .group-form .MuiAutocomplete-root,
.MuiPaper-root.MuiPaper-elevation .connect-inner .payment-box .group-form .MuiAutocomplete-root {
    width: 100%;
}

.modal-payment .payment-box .group-form .MuiAutocomplete-root input,
.MuiPaper-root.MuiPaper-elevation .connect-inner .payment-box .group-form .MuiAutocomplete-root input {
    border: none;
}

.modal-payment .payment-box .group-form .MuiAutocomplete-root fieldset,
.MuiPaper-root.MuiPaper-elevation .connect-inner .payment-box .group-form .MuiAutocomplete-root fieldset {
    border: 1px solid #e2e8f0;
    border-radius: 8px;
}

.modal-payment .payment-box .group-form textarea,
.modal-payment .payment-box .group-form input,
.MuiPaper-root.MuiPaper-elevation .connect-inner .payment-box .group-form input,
.MuiPaper-root.MuiPaper-elevation .connect-inner .payment-box .group-form textarea {
    position: relative;
    width: 100%;
    resize: none;
    border: 1px solid #e2e8f0;
    padding: 10px;
    font-size: 14px;
    color: #1f3462;
    border-radius: 5px;
}

.MuiPaper-root.MuiPaper-elevation {
    width: 750px;
    overflow: hidden;
}

.label-modal .modal-content .modal-header {
    position: absolute;
    right: 0;
    border: none;
    top: -17px;
    z-index: 99;
}

.label-modal .modal-content .modal-header button {
    background-color: transparent;
    border: none;
    font-size: 14px;
    color: #0065ff;
}

button {
    color: #0065ff;
}

.modal-payment .payment-box .option-box,
.MuiPaper-root.MuiPaper-elevation .connect-inner .payment-box .option-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.modal-payment .payment-box .option-box .add-option,
.MuiPaper-root.MuiPaper-elevation .connect-inner .payment-box .option-box .add-option {
    display: flex;
    align-items: center;
    margin-left: 15px;
}

.modal-payment .payment-box .option-box .add-option button,
.MuiPaper-root.MuiPaper-elevation .connect-inner .payment-box .option-box .add-option button {
    font-size: 14px;
    font-weight: 500;
    color: #0065ff;
    border: none;
    background-color: transparent;
    margin-right: 20px;
}

.modal-payment .payment-box .option-box .add-option button .icon,
.MuiPaper-root.MuiPaper-elevation .connect-inner .payment-box .option-box .add-option button .icon {
    margin-right: 5px;
}

.modal-payment .payment-box .option-box .save-btn,
.MuiPaper-root.MuiPaper-elevation .connect-inner .payment-box .option-box .save-btn {
    background-color: #0065ff;
    border: 1px solid #0065ff;
    transition: all 0.3s ease-in-out;
    color: #fff;
    padding: 9px 25px;
    border-radius: 8px;
    font-size: 14px;
}

.modal-payment .payment-box .option-box .save-btn:hover,
.MuiPaper-root.MuiPaper-elevation .connect-inner .payment-box .option-box .save-btn:hover {
    background-color: transparent;
    color: #0065ff;
}

.modal-payment .payment-box .new-option,
.MuiPaper-root.MuiPaper-elevation .connect-inner .payment-box .new-option {
    position: relative;
    display: none;
}

.modal-payment .payment-box .new-option.active,
.MuiPaper-root.MuiPaper-elevation .connect-inner .payment-box .new-option.active {
    display: block;
}

.modal-payment .payment-box .new-option .group-form.col-lg-1,
.MuiPaper-root.MuiPaper-elevation .connect-inner .payment-box .new-option .group-form.col-lg-1 {
    align-items: center;
    display: flex;
    justify-content: end;
}

.modal-payment .payment-box .new-option .group-form.col-lg-1 button,
.MuiPaper-root.MuiPaper-elevation .connect-inner .payment-box .new-option .group-form.col-lg-1 button {
    border: none;
    background-color: transparent;
    width: 30px;
    height: 30px;
    margin-top: 18px;
}

.modal.fade:not(.in).right .modal-dialog {
    -webkit-transform: translate3d(25%, 0, 0);
    transform: translate3d(25%, 0, 0);
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .table-area .table thead tr th .icon {
    margin-right: 4px;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .table-area .table thead tr th .icon img {
    width: 23px;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .table-area .table tbody tr td .edit-btn.edit-mapping .icon img {
    transition: all 0.3s ease-in-out;
    opacity: 0.5;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .table-area .table tbody tr td .edit-btn.edit-mapping:Hover .icon img {
    opacity: 1;
}

@media(max-width: 1610px) {
    .dashboard-page .content .dashboard-area .content-box .MuiBox-root .MuiStepper-root .MuiStep-root .MuiStepLabel-root:before {
        width: 91%;
    }
}

@media(max-width: 1470px) {
    .dashboard-page .content .dashboard-area .content-box .MuiBox-root .MuiStepper-root .MuiStep-root .MuiStepLabel-iconContainer svg {
        width: 26px;
    }

    .dashboard-page .content .dashboard-area .content-box .MuiBox-root .MuiStepper-root .MuiStep-root .MuiStepLabel-root .MuiStepLabel-labelContainer {
        left: -17%;
    }

    .dashboard-page .content .dashboard-area .content-box .MuiBox-root .MuiStepper-root .MuiStep-root .MuiStepLabel-label {
        font-size: 14px;
    }

    .dashboard-page .content .dashboard-area .content-box .connect-inner .connect-box .right-area .btn-style-two {
        padding: 6px 15px;
        font-size: 14px;
    }

    .dashboard-page .content .dashboard-area .content-box .MuiBox-root .MuiStepper-root .MuiStep-root .MuiStepLabel-root:before {
        width: 91%;
        right: 0px;
        top: 15px;
    }

    .dashboard-page .content .dashboard-area .content-box .MuiBox-root .MuiStepper-root .MuiStep-root .MuiStepLabel-root .MuiStepLabel-labelContainer .MuiStepLabel-label {
        font-size: 14px;
    }
}

@media(max-width: 1280px) {
    .dashboard-page .content .dashboard-area {
        padding: 25px 40px 20px;
    }

    .dashboard-page .content .dashboard-area .content-box .MuiBox-root .MuiStepper-root .MuiStep-root .MuiStepLabel-root:before {
        width: 90%;
    }
}

@media(max-width: 1210px) {
    .dashboard-page .content .dashboard-area .content-box .connect-inner .connect-box {
        padding: 0 10px 40px;
    }

    .dashboard-page .content .dashboard-area .content-box .connect-inner .connect-box .left-area h4 {
        font-size: 18px;
    }

    .dashboard-page .content .dashboard-area .content-box .connect-inner .connect-box .left-area h4 span {
        font-size: 14px;
    }

    .dashboard-page .content .dashboard-area .content-box .connect-inner .title-area h5,
    .dashboard-page .content .dashboard-area .content-box p,
    .dashboard-page .content .dashboard-area .content-box .connect-inner .table-area .table thead tr th,
    .dashboard-page .content .dashboard-area .content-box .connect-inner .table-area .table tbody tr td .edit-btn,
    .dashboard-page .content .dashboard-area .content-box .connect-inner .table-area .table tbody tr td .company-data h4 {
        font-size: 14px;
    }

    .dashboard-page .content .dashboard-area .content-box .connect-inner .title-area .select-area label,
    .dashboard-page .content .dashboard-area .content-box .connect-inner .table-area .table tbody tr td,
    .dashboard-page .content .dashboard-area .content-box .connect-inner .table-area .table tbody tr td input,
    .dashboard-page .content .dashboard-area .content-box .connect-inner .table-area .table tbody tr td a {
        font-size: 12px;
    }

    .dashboard-page .content .dashboard-area .content-box .connect-inner .table-area .table tbody tr td .edit-btn .icon,
    .dashboard-page .content .dashboard-area .content-box .connect-inner .table-area .table tbody tr td .company-data .icon {
        width: 13px;
    }

    .dashboard-page .content .dashboard-area .content-box .connect-inner .add-property .right-area .btn-style-two {
        padding: 6px 13px;
        margin-left: 15px;
        font-size: 12px;
    }
}

@media(max-width: 1199px) {
    .dashboard-page .content .dashboard-area .content-box .connect-inner .title-area {
        position: relative;
        flex-direction: column;
        text-align: left;
        align-items: flex-start;
    }

    .dashboard-page .content .dashboard-area .content-box .connect-inner .title-area.overview-title {
        flex-direction: row;
    }

    .dashboard-page .content .dashboard-area .content-box .MuiBox-root .MuiStepper-root .MuiStep-root .MuiStepLabel-root .MuiStepLabel-labelContainer .MuiStepLabel-label {
        font-size: 11px;
    }
}

@media(max-width: 991px) {
    .dashboard-page .content .dashboard-area .content-box .MuiBox-root .MuiStepper-root .MuiStep-root .MuiStepLabel-root .MuiStepLabel-labelContainer {
        left: -11%;
    }
}

@media(max-width: 768px) {
    .dashboard-page .content .dashboard-area .content-box .MuiBox-root .MuiStepper-root .MuiStep-root {
        zoom: 0.7;
    }

    .dashboard-page .content .dashboard-area .content-box .connect-inner .connect-box {
        padding: 0 10px 20px;
        flex-direction: column;
        margin-bottom: 20px;
    }

    .dashboard-page .content .dashboard-area .content-box .connect-inner .connect-box .right-area {
        margin-top: 10px;
    }

    .dashboard-page .content .dashboard-area .content-box .connect-inner .table-area .add-property {
        flex-direction: column;
        align-items: flex-start;
    }

    .dashboard-page .content .dashboard-area .content-box .connect-inner .add-property .right-area .btn-style-two {
        padding: 6px 13px;
        margin-left: 0;
        font-size: 12px;
        margin-right: 15px;
        margin-top: 15px;
    }

    .dashboard-page .content .dashboard-area .content-box .connect-inner.object-tab .checkbox-box .checkbox-area .label-box {
        width: 50%;
    }
}

@media(max-width: 670px) {
    .dashboard-page .content .dashboard-area .content-box .MuiBox-root .MuiStepper-root {
        flex-wrap: wrap;
    }

    .dashboard-page .content .dashboard-area .content-box .MuiBox-root .MuiStepper-root .MuiStep-root {
        zoom: 1;
        margin-bottom: -2px;
    }

    .dashboard-page .content .dashboard-area .content-box .connect-inner .title-area h5,
    .dashboard-page .content .dashboard-area .content-box p,
    .dashboard-page .content .dashboard-area .content-box .connect-inner .table-area .table thead tr th,
    .dashboard-page .content .dashboard-area .content-box .connect-inner .table-area .table tbody tr td .edit-btn {
        font-size: 13px;
    }

    .dashboard-page .content .dashboard-area .content-box .connect-inner .table-area .table-responsive .table {
        width: 480px;
    }

    .dashboard-page .content .dashboard-area .content-box .connect-inner .table-area.checkout-table .table-responsive .table {
        width: 570px;
    }

    /* width */
    .dashboard-page .content .dashboard-area .content-box .connect-inner .table-area .table-responsive::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    /* Track */
    .dashboard-page .content .dashboard-area .content-box .connect-inner .table-area .table-responsive::-webkit-scrollbar-track {
        background: #fff;
    }

    /* Handle */
    .dashboard-page .content .dashboard-area .content-box .connect-inner .table-area .table-responsive::-webkit-scrollbar-thumb {
        background: #e8e8e8;
        border-radius: 20px;
    }

    /* Handle on hover */
    .dashboard-page .content .dashboard-area .content-box .connect-inner .table-area .table-responsive::-webkit-scrollbar-thumb:hover {
        background: #e8e8e8;
        border-radius: 20px;
    }

    .dashboard-page .content .dashboard-area .content-box .connect-inner .table-area {
        position: relative;
        margin-bottom: 23px;
    }

    .modal-payment {
        max-width: 90% !important;
    }

    .modal-payment .payment-box .react-tabs__tab-list .react-tabs__tab {
        font-size: 12px;
        min-height: 55px;
        width: 40%;
    }

    .dashboard-page .content .dashboard-area .content-box .MuiBox-root .MuiStepper-root .MuiStep-root .MuiStepLabel-root:before {
        width: 95%;
        top: 11px;
    }

    .dashboard-page .content .dashboard-area .content-box .MuiBox-root .MuiStepper-root .MuiStep-root .MuiStepLabel-root .MuiStepLabel-labelContainer {
        left: auto;
    }
}

@media(max-width: 580px) {
    .dashboard-page .content .dashboard-area .content-box .connect-inner .title-area.overview-title {
        flex-direction: column;
    }

    .dashboard-page .content .dashboard-area .content-box .connect-inner .title-area.overview-title h5 {
        margin-bottom: 20px;
    }

    .dashboard-page .content .dashboard-area .content-box .MuiBox-root {
        position: relative;
        flex-direction: column;
        align-items: flex-end;
    }

    .dashboard-page .content .dashboard-area .content-box .MuiBox-root .migrat-btn {
        margin-right: 0;
        margin-bottom: 7px;
    }

    .modal-payment {
        max-width: 97% !important;
    }

    .modal-payment .payment-box .react-tabs__tab-list .react-tabs__tab:last-child {
        width: 14%;
    }
}

@media(max-width: 480px) {
    .dashboard-page .content .dashboard-area .content-box .MuiBox-root .MuiStepper-root {
        margin-right: 27px;
    }

    .dashboard-page .content .dashboard-area .content-box .connect-inner .connect-box .left-area .icon img {
        width: 22px;
    }

    .dashboard-page .content .dashboard-area .content-box .connect-inner .connect-box .left-area h4 {
        font-size: 15px;
    }

    .dashboard-page .content .dashboard-area .content-box .connect-inner .connect-box .left-area h4 span {
        font-size: 12px;
    }

    .dashboard-page .content .dashboard-area .content-box .MuiButtonBase-root {
        font-size: 13px !important;
        padding: 6px 15px !important;
    }

    .modal-payment .payment-box {
        padding: 16px 10px 0;
    }

    .dashboard-page .content .dashboard-area .content-box .connect-inner.object-tab .checkbox-box .checkbox-area .label-box {
        width: 100%;
    }
}

@media(max-width: 380px) {
    .dashboard-page .content .dashboard-area .content-box .MuiBox-root .MuiStepper-root .MuiStep-root {
        zoom: 0.8;
    }

    .dashboard-page .content .dashboard-area .content-box .connect-inner .connect-box .left-area h4 span {
        display: block;
        margin: 0;
    }

    .dashboard-page .content .dashboard-area .content-box .connect-inner .connect-box .left-area h4 span.devider-hubspot {
        display: none;
    }

    .modal-payment .payment-box .react-tabs__tab-list .react-tabs__tab,
    .modal-payment .payment-box .react-tabs__tab-list .react-tabs__tab:last-child {
        font-size: 13px;
        min-height: 50px;
        padding: 6px 9px;
        width: 100%;
    }
}

@media(max-width: 330px) {
    .dashboard-page .content .dashboard-area .content-box .MuiBox-root .MuiStepper-root .MuiStep-root {
        zoom: 0.7;
    }
}