@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

.dm-sans {
  font-family: "DM Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}
.work-sans {
  font-family: "Work Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}
/* font-family: "DM Sans", sans-serif;
font-family: "Work Sans", sans-serif; */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  color: #01194d;
}
::-webkit-input-placeholder {
  color: inherit;
}

::-moz-input-placeholder {
  color: inherit;
}

::-ms-input-placeholder {
  color: inherit;
}

.auto-container {
  position: static;
  max-width: 80%;
  padding: 0px 15px;
  margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  font-weight: normal;
  margin: 0px;
  background: none;
  line-height: 1.2em;
  font-family: "DM Sans", sans-serif;
  font-weight: normal;
  color: #01194d;
}
.btn-style-one {
  position: relative;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  color: #fff !important;
  text-decoration: none !important;
  border: 2px solid #0065ff;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  padding: 8px 30px;
  background-color: #0065ff;
  border-radius: 10px;
  z-index: 9;
  overflow: hidden;
  font-family: "Work Sans", sans-serif;
}

.btn-style-one:hover {
  background-color: transparent;
  color: #0065ff !important;
  border: 2px solid #0065ff;
}

.btn-style-two {
  position: relative;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  color: #0065ff !important;
  border: 2px solid #0065ff;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  text-decoration: none !important;
  padding: 8px 30px;
  background-color: transparent;
  border-radius: 10px;
  z-index: 9;
  overflow: hidden;
  font-family: "Work Sans", sans-serif;
}

.btn-style-two:hover,
.btn-style-two.active{
  background-color: #0065ff;
  color: #fff !important;
  border: 2px solid #0065ff;
}
.btn-style-three {
  position: relative;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  color: #fff !important;
  text-decoration: none !important;
  border: 2px solid #0a2440;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  padding: 8px 30px;
  background-color: #0a2440;
  border-radius: 10px;
  z-index: 9;
  overflow: hidden;
  font-family: "Work Sans", sans-serif;
}

.btn-small {
  padding: 6px 10px;
  border-radius: 23px;
}

.btn-style-three:hover {
  background-color: transparent;
  color: #0a2440 !important;
  border: 2px solid #0a2440;
}
a {
  color: #0065ff !important;
}
button:focus,
input:focus {
  outline: none !important;
  box-shadow: none !important;
}

input:-internal-autofill-selected {
  background: transparent !important;
  background-color: transparent !important;
}

input:-webkit-autofill {
  -webkit-transition-delay: 9999999s !important;
}

a {
  text-decoration: none;
  cursor: pointer;
}

a:hover,
a:focus,
a:visited {
  text-decoration: none;
  outline: none;
}

img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

/* ========== Custom Scroll Styles =========== */

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #E6E1DC;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #17090A;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #17090A;
}

/* ========== Main Modal Styles =========== */

.main-modal {
  max-width: 655px !important;
  top: 5%;
}

.main-modal .modal-header,
.modal-footer {
  border: none !important;
}

.main-modal .modal-content {
  position: relative;
  padding: 43px 20px;
  border-radius: 12px;
  border: 1px solid #F5F5F5;
  background: #FFF;
  box-shadow: -2px 4px 2.2px 0px rgba(0, 0, 0, 0.04);
}


.main-modal .modal-content .modal-header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 8px;
  right: 12px;
  z-index: 9;
}
.main-modal .modal-content .content-area {
  position: relative;
  padding: 40px 0;
  width: 100%;
  text-align: center;
}

.main-modal .modal-content .content-area h2 {
  color: #202020;
  text-align: center;
  font-family: "DM Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; /* 166.667% */
  letter-spacing: 0.48px;
}

.main-modal .modal-content .content-area p {
  color: #6D6D6D;
  text-align: center;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.28px;
}

.main-modal .modal-content .content-area .modal-btn {
  height: 48px;
  padding: 13px 0px 13.667px 0px;
  border-radius: 60px;
  background: #156CF7;
  display: block;
  text-align: center;
  color: #fff;
  margin: 40px auto 0;
  width: 64%;
}
.main-modal .modal-content .modal-header .close {
  color: #000;
}

.main-modal .modal-content .modal-header .modal-logo {
  display: flex;
  justify-content: center;
}

.main-modal .modal-content .modal-header .modal-logo img {
  width: 35%;
  padding: 25px;
}

@media(max-width: 1850px) {

}

@media(max-width: 1710px) {

}

@media(max-width: 1600px) {

}

@media(max-width: 1450px) {
  .auto-container {
    max-width: 90%;
  }
}


@media(max-width: 991px) {
  .btn-style-one,
  .btn-style-two,
  .btn-style-three{
    font-size: 14px;
    padding: 7px 18px;
  }
}

@media(max-width: 600px) {
  
  .auto-container {
    max-width: 90%;
    padding: 0 15px;
  }
  
}

/* @media(max-height: 960px) {
  .migratr-page .roadmap-sec .roadmap-box {
    margin-top: 108px;
  }
} */

@media(max-width: 555px) {

  .migratr-page .roadmap-sec .roadmap-box,
  .migratr-page .roadmap-sec .roadmap-box.style-two {
    width: 38%;
    margin-top: -81px;
  }
}

@media(max-width: 480px) {
  .auto-container {
    max-width: 90%;
  }

  .migratr-page .roadmap-sec .roadmap-box,
  .migratr-page .roadmap-sec .roadmap-box.style-two {
    width: 49%;
    margin-top: -81px;
  }

  .migratr-page .roadmap-sec .roadmap-box.style-two {
    margin-left: 0;
  }

  .migratr-page .roadmap-sec .roadmap-box .roadmap-text-box {
    position: relative;
    padding: 31px 25px 0;
    border-bottom: none;
    margin: 0 22px;
    left: 4px;
  }

  .migratr-page .roadmap-sec .roadmap-box .roadmap-text-box h3 img {
    width: 100%;
  }

  .migratr-page .roadmap-sec .roadmap-box .roadmap-text-box p {
    font-size: 10px;
  }
}

@media(max-width: 480px) {
  .migratr-page .roadmap-sec .roadmap-box .roadmap-text-box {
    position: relative;
    padding: 31px 25px 0;
    border-bottom: none;
    margin: 0 0;
    left: 4px;
  }
}