.navbar-main {
    display: flex;
    justify-content: end;
    padding-right: 24px;
}
.navbar-main {
    position: relative;
}

.navbar-main .dropdown-toggle {
    display: flex;
    font-size: 14px;
    font-weight: 500;
    align-items: center;
    border: none !important;
    color: #01194d !important;
    background-color: transparent !important;
}

.navbar-main .dropdown-toggle .user-img {
    position: relative;
    margin-right: 8px;
    margin-top: 1px;
}
.navbar-main .dropdown-menu {
    left: auto;
    right: 0;
    padding: 0;
    min-width: 139px;
}

.navbar-main .dropdown-menu .dropdown-item {
    font-size: 14px;
    padding: 8px;
    border-bottom: 1px solid #ccc;
    transition: all 0.3s ease-in-out;
}

.navbar-main .dropdown .dropdown-menu .dropdown-item:hover {
    background-color: #01194d;
    color: #fff !important;
}

.navbar-main .dropdown .dropdown-toggle .user-img img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
}

.navbar-main .dropdown .dropdown-toggle::after {
    border: none;
    width: 12px;
    height: 8px;
    background-size: 100%;
    margin-top: 3px;
    margin-left: 7px;
    background-repeat: no-repeat;
    background-image: url('../../static/images/dropdown-arrow.png');
}

@media(max-width: 480px){
    .navbar-main {
        padding-right: 2px;
    }
}