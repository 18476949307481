.singup-page {
    position: relative;
    margin-left: 0;
    padding-top: 20px;
    padding-right: 15px;
    background: rgb(0,26,77);
    background: linear-gradient(180deg, rgba(0,26,77,1) 0%, rgba(0,20,58,1) 50%, rgba(0,13,38,1) 100%);
    padding-bottom: 30px;
}
.singup-page .logo-top {
    position: absolute;
    top: 20px;
    left: 40px;
}

.singup-page .logo-top img {
    width: 214px;
}
.singup-page .content {
    position: relative;
    display: flex;
    min-height: 92.9vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.green-text{
    color: #ccff00 !important;
}
.singup-page .content .MuiCheckbox-colorSecondary.Mui-checked{
    color: #ccff00 !important; 
}
.singup-page .content .signup-form {
    border-radius: 12px;
    border: 1px solid #F5F5F5;
    background: #FFF;
    box-shadow: -2px 4px 2.2px 0px rgba(0, 0, 0, 0.04);
    padding: 35px 80px;
    width: 32%;
    margin: 0 auto;
}
.singup-page .content .signup-form label {
    font-size: 16px;
}
.singup-page .content .icon-area {
    text-align: center;
}

.singup-page .content .icon-area img {
    width: 59px;
}

.singup-page .content .icon-area h2 {
    margin: 20px 0;
    color: #fff;
    font-size: 25px;
    font-weight: 600;
}
.singup-page.singup-without-sidebar {
    margin: 0;
}
.singup-page.singup-without-sidebar .content .signup-form{
    width: 50%;
}
.singup-page .content .signup-form h2 {
    color: #202020;
    font-family: "DM Sans", sans-serif; 
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.singup-page .content .signup-form p {
    color: #001846;
    font-family: "DM Sans", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
}
.singup-page .content .signup-form p.MuiFormHelperText-root {
    color: #f44336 !important;
    font-size: 12px !important;
    position: absolute;
    bottom: -17px;
}
.singup-page .content .signup-form form.validatorForm .group-input label .singin-btn {
    color: #ccff00;
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
}
.trems-modal .content-area {
    padding: 0 !important;
}
.singup-page .content .signup-form p .singin-btn {
    color: #ccff00;
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.singup-page .content .signup-form form.validatorForm {
   position: relative;
}
.singup-page .content .signup-form label {
    font-size: 16px;
}

.singup-page .content .signup-form form.validatorForm .group-input .MuiGrid-item .MuiFormControl-root .MuiInputBase-root {
    font-size: 16px;
}

.singup-page .content .signup-form form.validatorForm .group-input .MuiGrid-item .MuiFormControl-root .MuiInputBase-root input {
    color: #000 !important;
    padding: 12px 15px;
    border: 1px solid #ebeff5;
    border-radius: 7px;
}
.singup-page .content .signup-form form.validatorForm .group-input {
    position: relative;
}

.singup-page .content .signup-form form.validatorForm .group-input .MuiGrid-item {
    padding: 0;
    margin-bottom: 20px;
}

.singup-page .content .signup-form form.validatorForm .group-input .MuiGrid-item .MuiFormControl-root {
    position: relative;
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-fullWidth.MuiInput-fullWidth.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-marginDense.MuiInput-marginDense {
    border-radius: 8px;
    border: 1px solid #000;
    padding: 5px 15px;
    color: #898989;
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-fullWidth.MuiInput-fullWidth.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-marginDense.MuiInput-marginDense::before {
    display: none;
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-fullWidth.MuiInput-fullWidth.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-marginDense.MuiInput-marginDense::after {
    display: none;
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-fullWidth.MuiInput-fullWidth.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-marginDense.MuiInput-marginDense input {
    color: #151624;
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.singup-page .content .signup-form form.validatorForm .group-input .MuiGrid-item.MuiGrid-grid-xs-6 {
    padding: 0px 3px;
}

.singup-page .content .signup-form .btn.btn-secondary {
    border-radius: 5px;
    background: #0065ff;
    width: 100%;
    border: 2px solid #0065ff;
    transition: all 0.3s ease-in-out;
    color: #fff;
    padding: 10px 15px;
    font-size: 14px;
}

.singup-page .content .signup-form .btn.btn-secondary:hover {
    background-color: transparent;
    color: #0065ff;
}

.singup-page .content p.forgetpass {
    font-size: 14px;
    margin-top: 16px;
    color: #fff;
    text-align: center;
}
.singup-page .content p.forgetpass .singin-btn {
    color: #ccff00 !important;
}
.singup-page .content .signup-form h4 {
    text-align: center;
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 16px;
    color: #001846;
}

.singup-page .content .signup-form p {}

.singup-page .content .signup-form .lable-area {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.singup-page .content .signup-form .lable-area a.resend-link {
    display: flex;
    color: #0065ff;
    font-size: 14px;
    font-weight: 600;
    align-items: center;
}

.singup-page .content .signup-form .lable-area a.resend-link .tooltip-icon {
    display: flex;
    align-items: center;
    position: relative;
}

.singup-page .content .signup-form .lable-area a.resend-link .tooltip-icon img {
    width: 18px;
    display: inline-block;
    margin-left: 8px;
}

.singup-page .content .signup-form .lable-area a.resend-link .tooltip-icon .overlaybox {
    position: absolute;
    right: -192px;
    width: 177px;
    text-align: left;
    background-color: #005ded;
    padding: 10px;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    opacity: 0;
    visibility: hidden;
}

.singup-page .content .signup-form .lable-area a.resend-link .tooltip-icon .overlaybox p {
    font-size: 13px;
    color: #fff;
    text-align: left;
    margin-bottom: 0;
}
.singup-page .content .signup-form .lable-area a.resend-link .tooltip-icon .overlaybox:before {
    content: "";
    position: absolute;
    left: -9px;
    top: 50%;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #005ded;
    transform: translateY(-50%);
}
.singup-page .content .signup-form .lable-area a.resend-link .tooltip-icon:hover .overlaybox{
    opacity: 1;
    visibility: visible;
}
@media(max-width: 1450px){
    .singup-page .content .signup-form{
        width: 36%;
    }
}
@media(max-width: 1200px){
    .singup-page .content .signup-form{
        width: 46%;
    }
}
@media(max-width: 991px){
    .singup-page .content .signup-form{
        width: 56%;
    }
}
@media(max-width: 767px){
    .singup-page .content .signup-form{
        width: 66%;
    }
}
@media(max-width: 650px){
    .singup-page .content .signup-form{
        width: 90%;
        padding: 35px;
    }
    .singup-page .content .icon-area {
        text-align: center;
        margin-top: 43px;
    }
    .singup-page .content .signup-form label {
        font-size: 14px;
    }
    .singup-page .content .signup-form .lable-area a.resend-link {
        font-size: 12px;
        margin-top: -6px;
    }
    .singup-page .content .signup-form .lable-area a.resend-link .tooltip-icon .overlaybox {
        right: auto;
        left: -119px;
        top: -59px;
    }
    
    .singup-page .content .signup-form .lable-area a.resend-link .tooltip-icon .overlaybox:before {
        left: auto;
        right: 40px;
        border-top: 10px solid transparent;
        border-bottom: 10px solid #005ded;
        top: auto;
        bottom: -6px;
        border-right: 10px solid transparent;
        transform: rotate(-48deg);
    }
}

@media(max-width: 380px){
    .singup-page .content .signup-form .lable-area {
        flex-direction: column;
    }
}