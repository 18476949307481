.migratr-sidebar {
  position: fixed;
  left: 0;
  top: 0;
  width: 300px;
  height: 100%;
  background: rgb(0,26,77);
  background: linear-gradient(180deg, rgba(0,26,77,1) 0%, rgba(0,20,58,1) 50%, rgba(0,13,38,1) 100%);
  z-index: 2;
  padding: 26px 25px;
}

.migratr-sidebar .sidebar-area {
  position: relative;
  height: 100%;
}
.migratr-sidebar .sidebar-area .logo-bottom {
  position: absolute;
  bottom: 0;
  width: 160px;
}
.migratr-sidebar .sidebar-area .logo {
  width: 177px;
  margin-bottom: 80px;
}
.migratr-sidebar .sidebar-area .sidebar-menu {
  list-style: none;
  margin-bottom: 0;
  margin-left: 25px;
}

.migratr-sidebar .sidebar-area .sidebar-menu li {
  margin-bottom: 31px;
}

.migratr-sidebar .sidebar-area .sidebar-menu li .nav-item {
  display: block;
  color: #fff !important;
  margin-bottom: 0;
  transition: all 0.3s ease-in-out;
  padding: 0;
  font-size: 16px;
}
.migratr-sidebar .sidebar-area .sidebar-menu li .nav-item .icon {
  position: relative;
  top: -2px;
  margin-right: 21px;
}
.migratr-sidebar .sidebar-area .sidebar-menu li .nav-item .icon img {
  width: 22px;
}
.migratr-sidebar .sidebar-area .sidebar-menu li .nav-item .icon svg path {
  fill: #5A5A5A60;
  transition: all 0.3s ease-in-out;
}

.migratr-sidebar .sidebar-area .sidebar-menu li .nav-item:hover,
.migratr-sidebar .sidebar-area .sidebar-menu li .nav-item.active {
  text-decoration: none;
  color: #ccff00 !important;
}

.migratr-sidebar .sidebar-area .sidebar-menu li .nav-item:hover .icon svg path,
.migratr-sidebar .sidebar-area .sidebar-menu li .nav-item.active .icon svg path{
  fill: #fff;
}

.migratr-sidebar .sidebar-area .sidebar-menu li:last-child .nav-item .nav-item .icon svg path {
    fill: transparent !important;
    stroke: #5A5A5A60;
}
.migratr-sidebar .sidebar-area .sidebar-menu li:last-child .nav-item .icon svg path {
    fill: transparent;
    stroke: #5A5A5A60;
}
.migratr-sidebar .sidebar-area .sidebar-menu li:last-child .nav-item.active .icon svg path, .migratr-sidebar .sidebar-area .sidebar-menu li:last-child .nav-item:hover .icon svg path {
    stroke: #fff;
}

.migratr-sidebar .sidebar-area.mobile-sidebar-area {
  display: none;
}
.migratr-sidebar .navbar-toggler {
  position: absolute;
  right: 0;
  bottom: 0;
  display: none;
}
/* ========== Custom Scroll Styles =========== */

/* width */
.migratr-sidebar::-webkit-scrollbar {
  width: 0;
}

/* Track */
.migratr-sidebar::-webkit-scrollbar-track {
  background: #E6E1DC;
}

/* Handle */
.migratr-sidebar::-webkit-scrollbar-thumb {
  background: #17090A;
}

/* Handle on hover */
.migratr-sidebar::-webkit-scrollbar-thumb:hover {
  background: #17090A;
}
.singup-page .navbar-toggler.sidebar-nav-toggle {
  position: relative;
  margin-top: -13px;
  margin-bottom: 0;
  display: none;
  z-index: 999;
}
@media(max-width: 991px){
  .migratr-sidebar .sidebar-area.mobile-sidebar-area,
  .migratr-sidebar .navbar-toggler,
  .singup-page .navbar-toggler.sidebar-nav-toggle{
    display: block;
  }
  .migratr-sidebar .sidebar-area{
    display: none;
  }
  .migratr-sidebar {
    width: 66px;
    padding: 100px 8px;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .migratr-sidebar .sidebar-area .sidebar-menu li .nav-item:hover, .migratr-sidebar .sidebar-area .sidebar-menu li .nav-item.active {
      border-radius: 4px;
      padding: 13px;
  }
  .migratr-sidebar .sidebar-area .sidebar-menu {
    margin-left: 15px;
  }
  .singup-page.active .migratr-sidebar.active {
    width: 300px;
    padding: 100px 0 0 50px;
  }
  .singup-page.active .migratr-sidebar.active .sidebar-area .sidebar-menu li .nav-item:hover, 
  .singup-page.active .migratr-sidebar.active .sidebar-area .sidebar-menu li .nav-item.active {
    border-radius: 4px 0 0 4px;
}
  .singup-page.active .migratr-sidebar.active .sidebar-area {
      display: block;
  }

  .singup-page.active .migratr-sidebar.active .sidebar-area.mobile-sidebar-area {
      display: none;
  }
  .migratr-sidebar .sidebar-area.mobile-sidebar-area .logo {
    position: absolute;
    width: 49px;
    top: -87px;
  }

  .migratr-sidebar .sidebar-area.mobile-sidebar-area .logo-bottom {
      width: 43px;
      text-align: center;
      left: 50%;
      transform: translateX(-50%);
  }
}


@media(max-width: 767px){
  .singup-page.active .migratr-sidebar.active {
    width: 222px;
    padding: 100px 0 0 17px;
  }
  .migratr-sidebar .sidebar-area .sidebar-menu li .nav-item {
      font-size: 13px;
  }
}

@media(max-width: 380px){
  .migratr-sidebar {
    width: 47px;
    padding: 100px 5px;
  }
  .migratr-sidebar .sidebar-area .sidebar-menu {
    margin-left: 9px;
  }
  .migratr-sidebar .sidebar-area.mobile-sidebar-area .logo {
    position: absolute;
    width: 32px;
    top: -87px;
    left: 50%;
    transform: translateX(-50%);
  }
  .migratr-sidebar .sidebar-area.mobile-sidebar-area .logo-bottom {
    width: 24px;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
}
}